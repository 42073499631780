import s_image from "../../img/Meeting-room.jpg";
import { Link } from "react-router-dom";

export function Offers() {
    return (
        <div className="offer" style={{ background: '#0F0F0F' }}>
            <div className="container-fluid px-5">
                <div className="row no-gutters align-items-center py-5 pl-5">
                    <div className="col-sm-12 col-lg-5 py-5">
                        <div className="d-flex flex-row  pb-0  pb-lg-4">
                            <h1 className="display-4 title__font">
                                <strong>Other Services </strong> <br />
                                <span className="subtitle__font">
                                    <span className="text__color"> we offer</span>
                                </span>
                            </h1>
                        </div>
                        <div className="d-flex flex-row py-4">
                            <div className="align-self-end">
                                <p className="lead">
                                    We are also there to provide you all these services besides our focused area.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row pt-5 pt-sm-0 pt-md-2 pt-lg-5">
                            <div className="align-self-end">
                                <Link to="/contactUs" className="button__style">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <img className="w-100" src={s_image} alt="s_image.PNG" width={100} height={"600"} />
                    </div>
                </div>
                <div className="text-center">
                    <p className="speciality__title align-center">The</p>
                    <h1 className="display-5 subtitle__font mb-lg-5">Find your seat at our table</h1>
                </div>
            </div>
        </div>
    )
}