
import TaxImage from "../../img/Tax.jpg";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function Properties() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  {/* Performs similarly to componentDidMount in classes */ }
  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  {/* There is no need for a render function with Hooks */ }

  return (
    <section id="properties__section" className="text-dark">
      <div className={`${isMobile ? "container-fluid py-5" : "container py-5"}`}>
        <div className="row">
          <div className="propertiesSection__left col-md-12 col-lg-5  pl-sm-5 pl-xl-0 order-last">
            <div className="propertiesLeft__bgimage">
              <img src={TaxImage} className="img-fluid" alt="Bonding" />
              <div className="rounded__border"></div>
            </div>
          </div>
          <div className="propertieSection__right col-md-12 col-lg-7 pr-xl-0 order-first">
            <h1 className="pb-4 display-5 subtitle__font">
              Property Business
              <span className="d-block text-yellow">Accounts</span>
            </h1>
            <p className="lead pb-4">
              Property business accounts preparation involves meticulously documenting financial transactions related to real estate holdings. It includes tracking rental income, property expenses, and ensuring compliance with tax regulations. Accurate accounting provides a clear financial picture, supports informed decision-making, and helps property owners to optimize their investment.
              <br />
              <br />
              We provide professional guidance to streamline this crucial aspect of property management.
            </p>
            <div className="d-flex flex-row pt-4">
              <Link to="/contactUs" className="button__style">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}