import "./CompanyAccounts.css";
import { Link } from "react-router-dom";

export function CompanyAccounts() {
  return (
    <div className="companyAccounts">
      {/* -companyAccounts upper section start*/}

      <section id="companyAccounts_upper__section">
        <div className="companyAccounts__backgroundImage">
          <div className="dark-overlay">
            <div className="companyAccounts__inner">
              <div className="container-fluid fix__margin">
                <div className="row pt-3 pt-md-5 align-items-start">
                  <div className="col-md-12 col-lg-8 ">
                    <div className="d-flex flex-row  py-4">
                      <h1 className="display-4 title__font">
                        <strong>
                          Personal Tax
                          <span className="text__color"> Return</span>
                        </strong>
                      </h1>
                    </div>
                    <div className="d-flex flex-row py-4">
                      <div className="align-self-end">
                        <p className="lead">
                          Our professional team is there to help every
                          individual with a variety of income including wages,
                          self-employment income, rental income, investment
                          income and any other sources of earnings.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row pt-0 pt-md-5">
                      <div className="align-self-end">
                        <Link to="/contactUs" className="button__style">
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -companyAccounts upper section end*/}

      {/* -companyAccounts lower section start*/}

      <section id="companyAccounts_lower__section" className="py-5">
        <div className="companyAccounts_lower__inner">
          <div
            className="container-fluid companyAccounts_lower__container"
            style={{ background: "#181818" }}
          >
            <div className="row no-gutters">
              <div className="">
                <h1 className="pb-4 display-4 subtitle__font">
                  Solutions designed for
                  <span
                    className="d-block subtitle__style"
                    style={{ color: "#d3ac6a" }}
                  >
                    all your needs
                  </span>
                </h1>
                <p className="lead pb-4">
                  Managing your personal tax affairs requires attention to
                  detail, expertise, and a commitment to compliance. At Royal
                  Wharf Accountants, our Personal Tax Return services are
                  designed to alleviate the complexities associated with
                  individual taxation.
                  <br />
                  <br />
                  Our seasoned professionals take a personalised approach,
                  understanding that each client's financial situation is
                  unique. We guide you through the entire process, ensuring that
                  your tax return is prepared accurately and submitted in a
                  timely manner, meeting all HMRC deadlines.
                  <br />
                  <br />
                  Optimising your tax position is a key focus of our service. We
                  identify opportunities for deductions and allowances, working
                  to minimise your tax liabilities within the bounds of the law.
                  <br />
                  <br />
                  Accurate record-keeping is paramount for a smooth Personal Tax
                  Return process. Our team assists you in organising and
                  maintaining the necessary documentation, making the entire
                  experience more streamlined and stress-free.
                  <br />
                  <br />
                  We handle all communications with HMRC on your behalf,
                  addressing queries and ensuring that your tax affairs are in
                  order. This allows you to focus on your personal and
                  professional priorities with confidence.
                  <br />
                  <br />
                  Choose Royal Wharf Accountants for reliable and comprehensive
                  Personal Tax Return services. Contact us today to discuss your
                  individual tax needs and discover how we can contribute to
                  your financial well-being.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -companyAccounts lower section end*/}
    </div>
  );
}
