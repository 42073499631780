import React from "react";
import { useRef, useState, useEffect } from "react";
import "./Home.css";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import HandshakeImage from "./img/handshake.jpg";
import FloorImage from "./img/Floor.jpg";
import ClockIcon from "./img/clock__icon.png";
import CommentIcon from "@material-ui/icons/Comment";
import CustomerIcon from "./icons/CustomerIcon";
import { Quote } from "./components/otherService/Quote";
import { Link } from "react-router-dom";

function Home() {
  // State to manage the button text
  const [buttonText, setButtonText] = useState("Read More");
  const [visionButtonText, setvisionButtonText] = useState("Read More");

  const aboutRef = useRef();
  const visionRef = useRef();

  const showAboutUsExtraline = () => {
    aboutRef.current.classList.toggle("aboutus__active");
    // Change the button text when clicked
    if (buttonText === "Read More") {
      setButtonText("Read Less");
    } else {
      setButtonText("Read More");
    }
  };

  const showVisionExtraline = () => {
    visionRef.current.classList.toggle("vision__active");
    // Change the button text when clicked
    if (visionButtonText === "Read More") {
      setvisionButtonText("Read Less");
    } else {
      setvisionButtonText("Read More");
    }
  };

  // Width of the screen
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <div className="home">
      {/* -Head section start*/}

      <section id="home__section">
        <div className="head__backgroundImage">
          <div className="dark-overlay">
            <div className="home-inner">
              <div className="container-fluid fix__margin">
                <div className="row align-items-start">
                  <div className="col-sm-12 col-lg-10 p-0">
                    <div className="d-flex flex-row  pb-0  pb-lg-4">
                      <h1 className="display-4 title__font">
                        <strong>"Your Prosperity : </strong> <br />
                        <span className="subtitle__font">
                          {" "}
                          We craft{" "}
                          <span className="text__color"> the chapter.</span>"
                        </span>
                      </h1>
                    </div>
                    <div className="d-flex flex-row py-4">
                      <div className="align-self-end">
                        <p className="lead">
                          At Royal Wharf Accountants, we're more than just
                          numbers; we're your trusted Financial partners. With a
                          commitment to excellence and a passion for precision,
                          we have been serving individuals and businesses based
                          in London.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="align-self-end">
                        <p className="lead">
                          We believe that building strong, long-lasting
                          relationships with our clients is the key to our
                          mutual success. We take the time to get to know you,
                          your business, and your financial aspirations. By
                          understanding your specific needs and goals, we can
                          offer personalised solutions that drive your financial
                          growth and peace of mind.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row pt-5 pt-sm-0 pt-md-5 pt-lg-5">
                      <div className="align-self-end">
                        <Link to="/contactUs" className="button__style ">
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -Head section end*/}

      {/* About Us Section start*/}

      <section id="aboutus__section" className="text-dark py-5">
        <div
          className={`${isMobile ? "container-fluid py-5" : "container py-5"}`}
        >
          <div className="row">
            <div className="aboutus_section__left col-md-12 col-lg-6  pl-sm-5 pl-lg-0 order-last order-lg-first ">
              <div className="left__bgimage">
                <img src={HandshakeImage} className="img-fluid" alt="Bonding" />
              </div>
            </div>
            <div className="aboutus_section__right col-md-12 col-lg-6 pr-xl-0 order-first order-lg-last ">
              <p className="about__title">About Us</p>
              <h1 className="pb-4 display-5 subtitle__font">
                Smart solutions designed
                <span className="d-block">for your business</span>
              </h1>
              <p className="lead pb-4">
                Our team of experienced and highly skilled accountants is the
                backbone of our success. With a deep understanding of Accounting
                principles, Tax laws and the latest industry trends, we are
                dedicated to providing you with accurate, timely, and reliable
                Financial services. We take pride in our continuous learning and
                adaptability to ensure we stay at the forefront of our field.
              </p>
              <p ref={aboutRef} className="lead aboutus__extraLines">
                Our worldwide diversified team also can guide, if you need our
                support in multiple regions.
              </p>
              <div className="d-flex flex-row pt-4">
                <button
                  onClick={showAboutUsExtraline}
                  className="button__style"
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Section end*/}

      {/* The Speciality section start*/}

      <section id="speciality__section" className="text-center py-5">
        <div className={`${isMobile ? "container-fluid" : "container"}`}>
          <p className="speciality__title align-center">The</p>
          <h1 className="display-5 subtitle__font mb-lg-5">Speciality Of Us</h1>
          <div className="row mt-5 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
            <div className="col mb-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className="mt-2 mb-5 align-center">
                    <img
                      src={ClockIcon}
                      height={100}
                      width={100}
                      alt="Clock Icon"
                    />
                  </h4>
                  <h5 className="card-title text-white">QUICK RESPONSE</h5>
                  <p className="card__text ">
                    At Royal Wharf Accountants, we understand the importance of
                    timely assistance in the world of finance. Your inquiries
                    are important to us, and we are committed to providing quick
                    and efficient responses to ensure your financial needs are
                    met promptly.
                    <br />
                    <br />
                    For urgent matters, you can always reach our support team at
                    020 8079 1717.
                  </p>
                </div>
              </div>
            </div>
            <div className="col mb-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className=" mt-2 mb-5 align-center">
                    <CommentIcon className="comment__icon" />
                  </h4>
                  <h5 className="card-title text-white">GREAT COMMUNICATION</h5>
                  <p className="card__text">
                    We prioritise communication as a cornerstone of our client
                    relationships. We understand that clear and transparent
                    communication is vital in the world of finance. Responsive &
                    regular support, Tailored communication & Proactive solution
                    are our main speciality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col mb-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className="mt-2 mb-5 align-center">
                    <CustomerIcon color={"#D3AC6A"} />
                  </h4>
                  <h5 className="card-title">CUSTOMER SATISFACTION</h5>
                  <p className="card__text">
                    With years of experience in the industry, our team of
                    seasoned professionals provides expert guidance and support
                    every step of the way. Whether you're a small business owner
                    or a large corporation, we have the expertise to help you
                    navigate the complexities of finance and achieve your
                    objectives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* The Speciality section end*/}

      {/* Our vision Section */}

      <section id="ourvision__section" className="text-dark mt-5 py-5">
        <div
          className={`${isMobile ? "container-fluid py-5" : "container py-5"}`}
        >
          <div className="row">
            <div className="ourvision_section__left col-sm-12 col-md-12 col-lg-6 ">
              <p className="ourVision__title">Our Vision</p>
              <h1 className="pb-4 display-5 subtitle__font">
                We feel your pain
                <span className="d-block">points</span>
              </h1>
              <p className="lead pb-4">
                Our vision is to empower our clients to make informed Financial
                decisions and achieve their ultimate goals. We understand that
                navigating the complexities of Tax regulations, Financial
                planning and Accounting can be overwhelming. That's where we
                come in. We simplify the Financial landscape and provide
                tailored solutions to meet your unique needs.
              </p>
              <p ref={visionRef} className="lead vision__extraLines">
                Ful-filling customer demand and understanding their necessities
                is our main priorities.
              </p>
              <div className="d-flex flex-row py-5">
                <button onClick={showVisionExtraline} className="button__style">
                  {visionButtonText}
                </button>
              </div>
            </div>
            <div className="ourvision_section__right col-sm-12 col-md-12 col-lg-6  px-4 px-lg-0">
              <div className="ourvision_right__bgimage">
                <div className="ourvision_right__border"></div>
                <img src={FloorImage} className="img-fluid" alt="Bonding" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* OUR SERVICES section start*/}
      <section id="ourService__section" className="text-center py-5">
        <div className={`${isMobile ? "container-fluid" : "container"}`}>
          <p className="ourService__title align-center">OUR SERVICES</p>
          <h1 className="display-5 subtitle__font">What We Offer</h1>
          <div className="row mt-5 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
            <div className="col my-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className="card__number mt-2 mb-5 align-center">1</h4>
                  <h5 className="card-title">VAT RETURNS</h5>
                  <p className="card__text p-3">
                    Navigating the complexities of Value Added Tax (VAT) is a
                    crucial aspect of financial management for businesses. At
                    Royal Wharf Accountancy, we offer comprehensive VAT services
                    designed to streamline your processes and ensure compliance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col my-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className="card__number mt-2 mb-5 align-center">2</h4>
                  <h5 className="card-title text-white">
                    COMPANY ACCOUNTS & CORPORATION TAX
                  </h5>
                  <p className="card__text">
                    Our expert team offers Company accounts preparation and Tax
                    submission on time. Company accounts provide a snapshot of a
                    company's financial health and are used for various
                    purposes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col my-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className="card__number mt-2 mb-5 align-center">3</h4>
                  <h5 className="card-title">SELF ASSESSMENT TAX RETURNS</h5>
                  <p className="card__text p-4">
                    Managing your personal tax affairs can be a complex and
                    time-consuming task. At Royal Wharf Accountants, we offer
                    specialised services to simplify the process of Self
                    Assessment Tax Returns, ensuring compliance and peace of
                    mind.
                  </p>
                </div>
              </div>
            </div>
            <div className="col my-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className="card__number mt-2 mb-5 align-center">4</h4>
                  <h5 className="card-title text-white">PAYROLL</h5>
                  <p className="card__text p-4">
                    The Royal Wharf Accountants team will handle tasks like
                    calculating employee wages, managing tax withholdings,
                    ensuring compliance with tax regulations, and maintaining
                    accurate payroll records.
                  </p>
                </div>
              </div>
            </div>
            <div className="col my-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className="card__number mt-2 mb-5 align-center">5</h4>
                  <h5 className="card-title text-white">CRYPTOCURRENCY TAX</h5>
                  <p className="card__text p-4">
                    Cryptocurrency tax refers to the taxation of transactions
                    involving digital currencies. It includes capital gains tax
                    on profits from buying and selling cryptocurrencies, income
                    tax on cryptocurrency received as payment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col my-5">
              <div className="card card__boxShadow h-100">
                <div className="card-body">
                  <h4 className="card__number mt-2 mb-5 align-center">6</h4>
                  <h5 className="card-title">OTHER SERVICES</h5>
                  <p className="card__text p-4">
                    Our commitment to comprehensive financial support goes
                    beyond standard accounting services. Explore our additional
                    offerings designed to meet the diverse needs of our clients
                    Including BusinessConsulting, Payroll management, Tax
                    planning and strategy and Payroll management.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* OUR SERVICES section end*/}

      {/* Quote Section start*/}
      <Quote />
      {/* Quote Section end*/}

      {/* Contact Us Modal */}

      {/* <div className="modal" id="contactModal">
          <div className="container">
            <div className="modal-dialog">
              <div className="modal-content bg-dark">
                <div className="modal-header">
                  <h3>Contact Us</h3>
                  <button className="close text-light" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    action="https://formspree.io/foyezahmedfj20@gmail.com"
                    method="POST"
                  >
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input
                        type="text"
                        name="Name"
                        id="name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        name="Email"
                        id="email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label for="massege">Massege</label>
                      <textarea
                        name="Massege"
                        id="massege"
                        className="form-control"
                      ></textarea>
                    </div>
                    <input type="submit" value="Send" className="btn btn-success" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
}

export default Home;
