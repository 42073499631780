
import CryptocurrencyImage from "../../img/Cryptocurrency.jpg";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function CashFlow() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  {/* Performs similarly to componentDidMount in classes */ }
  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  {/* There is no need for a render function with Hooks */ }

  return (
    <section id="properties__section" className="text-dark">
      <div className={`${isMobile ? "container-fluid py-5" : "container py-5"}`}>
        <div className="row">
          <div className="propertiesSection__left col-md-12 col-lg-5  pl-sm-5 pl-xl-0 order-last">
            <div className="propertiesLeft__bgimage">
              <img src={CryptocurrencyImage} className="img-fluid" alt="Bonding" />
              <div className="rounded__border"></div>
            </div>
          </div>
          <div className="propertieSection__right col-md-12 col-lg-7 pr-xl-0 order-first">
            <h1 className="pb-4 display-5 subtitle__font">
              Cryptocurrency
              <span className="d-block text-yellow">Tax</span>
            </h1>
            <p className="lead pb-4">
              Cryptocurrency tax refers to the taxation of transactions involving digital currencies. It includes capital gains tax on profits from buying and selling cryptocurrencies, income tax on cryptocurrency received as payment, mining rewards, or income, and reporting requirements for these transactions. Tax laws for cryptocurrencies vary by jurisdiction, making it important for individuals and businesses to understand and comply with their local tax regulations.
              <br />
              <br />
              We have an expert team, who are mostly experienced in handling cryptocurrency taxes. You can completely rely on us without any doubt.

            </p>
            <div className="d-flex flex-row pt-4">
              <Link to="/contactUs" className="button__style">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}