import "./Footer.css";
import Vertical__Logo from "../img/Vertical__Logo.png"; // Tell webpack this JS file uses this image
import { FaHome } from "react-icons/fa";
import { FaServicestack } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import XIcon from "@mui/icons-material/X";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer_section layout_padding">
      <div className="container-fluid">
        <div className="footer_section_2">
          <div className="row">
            <div className="col-lg-3">
              <div className="footer__imageContainer d-flex justify-content-center">
                <a href="/">
                  <img
                    src={Vertical__Logo}
                    height={300}
                    width={300}
                    alt="Rowal Wharf"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="mb-4">
                <h2 className="footer__title">CONTACT</h2>
                <a
                  className="item"
                  href="tel:02080791717"
                  aria-label="Call us at 020 8079 1717"
                >
                  020 8079 1717
                </a>
                <a
                  className="item"
                  href="tel:+447889496845"
                  aria-label="Call us at +44 7889 496845"
                >
                  +447889496845
                </a>
                <a className="item" href="mailto:info@rwaccountants.com">
                  info@rwaccountants.com
                </a>
              </div>
              <div>
                <h2 className="footer__title d-block">INFORMATION</h2>
                <a
                  href="https://drive.google.com/file/d/1Wyv1R2Qmp4TPLlWX_twAsprnu9Qr-Y29/view?usp=sharing"
                  className="site_map__link "
                >
                  Privacy policy
                </a>
                <a
                  href="https://docs.google.com/document/d/16TJ416tVGSqd83z6PS2ugzajk4_7Flgc9NgUgfURFWk/edit?usp=share_link"
                  className="site_map__link"
                >
                  Terms & Condition
                </a>
              </div>
            </div>
            <div className="col-lg-3">
              <div>
                <h2 className="footer__title">SITE MAP</h2>
                <div className="pt-5">
                  <Link className="site_map__link" to="/">
                    <FaHome className="icon__style" />
                    Home
                  </Link>
                  <Link className="site_map__link" to="/otherServices">
                    <FaServicestack className="icon__style" />
                    Services
                  </Link>
                  <Link className="site_map__link" to="/contactUs">
                    <FaPhoneAlt className="icon__style" />
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <h2 className="footer__title font__size subtitle__font">
                <span className="border__bottom">Royal</span>{" "}
                <span className="text__white"> Wharf </span>
              </h2>
              <div className="footer_social_icon pb-4">
                <ul className="footer_item__list">
                  <li className="footer_item">
                    <a
                      className=""
                      href="https://www.linkedin.com/in/royal-wharf-accountants-4031b72b8/"
                    >
                      <LinkedInIcon className="li__icon" />
                    </a>
                  </li>
                  <li className="footer_item">
                    <a
                      className=""
                      href="https://www.facebook.com/profile.php?id=61551439883946"
                    >
                      <FacebookIcon className="fb__icon" />
                    </a>
                  </li>
                  <li className="footer_item">
                    <a className="" href="https://twitter.com/RoyalwharfA">
                      <XIcon className="tw__icon" />
                    </a>
                  </li>
                </ul>
              </div>
              <p className="pr-2">
                ROYAL WHARF ACCOUNTANTS LIMITED - Registered Company No:
                14363903 - Registered Address: 3rd Floor, 86-90 Paul Street, London, EC2A
                4NE.
              </p>
            </div>
          </div>
          <div className="row text-center mt-5">
            <p className="copyright">
              Copyright &copy; 2024 Royal Wharf. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
