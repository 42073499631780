import AccountingImage from "../../img/Accounting.jpg";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function DebtRepo() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    {/* Performs similarly to componentDidMount in classes */ }
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    return (
        <section id="newCompany__section" className="text-dark">
            <div className={`${isMobile ? "container-fluid py-5" : "container py-5"}`}>
                <div className="row">
                    <div className="companySection__left col-md-12 col-lg-5  pl-sm-5 pl-xl-0 order-last order-lg-first">
                        <div className="companyLeft__bgimage">
                            <img src={AccountingImage} className="img-fluid" alt="Bonding" />
                            <div className="rounded__border"></div>
                        </div>
                    </div>
                    <div className="companySection__right col-md-12 col-lg-7 pr-xl-0 order-first order-lg-last">
                        <h1 className="pb-4 display-5 subtitle__font">
                            Debt Recovery
                        </h1>
                        <p className="lead pb-4">
                            Effective debt recovery can help businesses maintain healthy cash flow and financial stability while adhering to legal and ethical practices. It often requires a strategic approach, negotiation skills, and sometimes legal action to ensure that creditors receive the funds they are owed.
                            <br />
                            <br />
                            The Royal Wharf has an expert and trained team of debt recovery, who are dedicated to retrieving our client’s outstanding debts owed by any individuals or companies.
                        </p>
                        <div className="d-flex flex-row pt-4">
                            <Link to="/contactUs" className="button__style">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}