import React from "react";
import "./Vat.css";
import "../App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Quote } from "../components/otherService/Quote";
import { Payroll } from "./Payroll";
import { Link } from "react-router-dom";

export function Vat() {
  return (
    <div className="vat">
      {/* -vat_upper__section start*/}

      <section id="vat_upper__section">
        <div className="vat__backgroundImage">
          {/* -Head body section  start*/}
          <div className="dark-overlay">
            <div className="vat_upper__inner">
              <div className="container-fluid fix__margin">
                <div className="row align-items-start">
                  <div className="col-sm-12 col-lg-10 p-0">
                    <div className="d-flex flex-row  py-4">
                      <h1 className="display-4 title__font">
                        <strong>
                          VAT <span className="text__color"> Returns </span>
                        </strong>
                        <br />
                      </h1>
                    </div>
                    <div className="d-flex flex-row py-4">
                      <div className="align-self-end">
                        <p className="lead">
                          Our seasoned professionals bring a wealth of
                          experience in understanding the complexities of VAT
                          regulations. Whether you're a startup aiming to
                          register for VAT or a well-established business
                          considering de-registration, we guide you through the
                          process, ensuring adherence to all requirements while
                          optimising your VAT position.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row py-5">
                      <div className="align-self-end">
                        <Link to="/contactUs" className="button__style">
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* -Head body section  end*/}
        </div>
      </section>
      {/* -vat_upper__sectionend*/}

      {/* -vat_lower__section start*/}

      <section id="vat_lower__section" className="py-5">
        <div className="vat_lower__inner">
          <div
            className="container-fluid vat_lower__container"
            style={{ background: "#181818" }}
          >
            <div className="row no-gutters">
              <div className="">
                <h1 className="pb-4 display-4 subtitle__font">
                  Solutions designed for
                  <span
                    className="d-block subtitle__style"
                    style={{ color: "#d3ac6a" }}
                  >
                    all your needs
                  </span>
                </h1>
                <p className="lead pb-4">
                  Stay on top of your VAT responsibilities with our meticulous
                  preparation and submission of periodic VAT returns. We handle
                  the paperwork, allowing you to focus on running your business
                  with confidence.
                  <br /><br />
                  Our approach extends beyond compliance. We offer strategic VAT
                  planning and advice to minimise your tax liabilities,
                  identifying opportunities for cost savings and compliance
                  enhancements.
                  <br /><br />
                  Avoid costly mistakes with our thorough VAT compliance
                  reviews. We assess your processes to identify potential
                  issues, ensuring your business meets all VAT obligations
                  accurately.
                  <br /><br />
                  Choose Royal Wharf Accountants for reliable and comprehensive
                  VAT services. We are here to support your business every step
                  of the way.
                  <br /><br />
                  Contact us today to discuss your VAT needs and discover how we
                  can contribute to your financial success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* vat_lower__section  end*/}

      {/* Payroll  section  start*/}
      <Payroll />
      {/* Payroll  section  end*/}

      {/* Quote Section start*/}
      <Quote />
      {/* Quote Section end*/}
    </div>
  );
}
