import CalculatorImage from "../../img/Calculator.jpg";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function NewCompany() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    {/* Performs similarly to componentDidMount in classes */ }
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    return (
        <section id="newCompany__section" className="text-dark">
            <div className={`${isMobile ? "container-fluid py-5" : "container py-5"}`}>
                <div className="row">
                    <div className="companySection__left col-md-12 col-lg-5  pl-sm-5 pl-xl-0 order-last order-lg-first">
                        <div className="companyLeft__bgimage">
                            <img src={CalculatorImage} className="img-fluid" alt="Bonding" />
                            <div className="rounded__border"></div>
                        </div>
                    </div>
                    <div className="companySection__right col-md-12 col-lg-7 pr-xl-0 order-first order-lg-last">
                        <h1 className="pb-4 display-5 subtitle__font">
                            New Company
                            <span className="d-block text-yellow">Setup</span>
                        </h1>
                        <p className="lead pb-4">
                            Starting a new company in the UK involves registering with Companies House, selecting a suitable business structure (e.g. LTD or LLP), and fulfilling legal and financial requirements. Careful planning, market research, and compliance with UK regulations are key to a successful launch.
                            <br/>
                            <br/>
                            We help our clients to start their own company and become self-employed. As a chartered account we relief their extra headaches by planning their company formation, tax and all other related areas, so they can completely focus on their business.
                        </p>
                        <div className="d-flex flex-row pt-4">
                            <Link to="/contactUs" className="button__style">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}