import React from "react";
import { useRef } from "react";
import "../../App.scss";
import "./Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../img/horizontal_transparent__logo.png"; // Tell webpack this JS file uses this image
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import XIcon from "@mui/icons-material/X";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { FaServicestack } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function Header() {

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div id="common__header" className="common__header">
      <div className="container-fluid">
        <div className="row py-4 justify-content-md-center align-items-center">
          <div className="site_header__left">
            <div className="expand">
              <button className="nav-btn ml-4" onClick={showNavbar}>
                <FaBars />
              </button>
            </div>
          </div>
          <div className="site_header__centre">
            <a href="/">
              <img src={logo} height={80} width={250} alt="Rowal Wharf" />
            </a>
          </div>
          <div className="site_header__right">
            <div className="header__right_inner">
              <div className="navbar__collapse" id="navbarNav">
                <ul className="header__right_inner_list">
                  <li className="header__right_inner_list_item">
                    <a
                      className="nav-link Twitter__icon"
                      href="https://twitter.com/RoyalwharfA"
                    >
                      <XIcon className="" />
                    </a>
                  </li>
                  <li className="header__right_inner_list_item">
                    <a
                      className="nav-link facebook__icon"
                      href="https://www.facebook.com/profile.php?id=61551439883946"
                    >
                      <FacebookIcon className="" />
                    </a>
                  </li>
                  <li className="header__right_inner_list_item">
                    <a
                      className="nav-link LinkedIn__icon"
                      href="https://www.linkedin.com/in/royal-wharf-accountants-4031b72b8/"
                    >
                      <LinkedInIcon className="" />
                    </a>
                  </li>
                  <li className="header__right_inner_list_item">
                    <Link
                      className="nav-link"
                      to="/contactUs"
                    >
                      <FaPhoneAlt className="icon__style" />
                      Contact us
                    </Link>
                  </li>
                  <li className="header__right_inner_list_item">
                    <div className="dropdowns">
                      <button className="dropbtn nav-link">
                        <FaServicestack className="icon__style" />
                        Service
                        <FaCaretDown className="" />
                      </button>
                      <div className="dropdown__content">
                        <Link to="/vat">Vat & Payroll</Link>
                        <Link to="/company&Accounts">
                          Personal & Company Tax
                        </Link>
                        <Link to="/otherServices">Other Services</Link>
                      </div>
                    </div>
                  </li>

                  <li className="header__right_inner_list_item">
                    <Link className="nav-link" to="/">
                      <FaHome className="icon__style" />
                      Home
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={navRef} className="container-fluid header__expanded_Section">
        <Link onClick={showNavbar} className="item" to="/">
          Home
        </Link>
        <Link onClick={showNavbar} className="item" to="/otherServices">
          Our Services
        </Link>
        <Link onClick={showNavbar} className="item" to="/contactUs">
          Contact Us
        </Link>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </div>
    </div>
  );
}

export default Header;
