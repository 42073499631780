import React, { useRef } from "react";
import "./ContactUs.css";
import s_image from "../img/Meeting-room.jpg";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export function ContactUs() {
  const sectionRef = useRef(null);

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const successMsg = () => toast.success("Email send succefully!");
  const errorMsg = () => toast.error("Email send failed!");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_en210gn",
        "template_1wcmslo",
        form.current,
        "nbO_EFd_WvfJtddre"
      )
      .then(
        (result) => {
          console.log(result);
          successMsg();
        },
        (error) => {
          errorMsg();
        }
      );
  };
  return (
    <div className="contactUs" style={{ background: "#0F0F0F" }}>
      <section className="contactUs__top">
        <div className="container-fluid ">
          <div className="contactUs__topInner">
            <div className="row align-items-center py-5 pl-5">
              <div className="col-sm-12 col-lg-5 py-5">
                <div className="d-flex flex-row  pb-0  pb-lg-4">
                  <h1 className="display-4 title__font">
                    <strong>Let ' s create</strong> <br />
                    <span className="subtitle__font">
                      <span>fine</span>
                      <span className="text__color"> progress together</span>
                    </span>
                  </h1>
                </div>
                <div className="d-flex flex-row py-4">
                  <div className="align-self-end">
                    <p className="lead">
                      We are also there to provide you all these services
                      besides our focused area.
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row pt-5 pt-sm-0 pt-md-2 pt-lg-5">
                  <div className="align-self-end">
                    <Link
                      to="/contactUs"
                      onClick={scrollToSection}
                      className="button__style"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-7">
                <div className="contactUs__topImage m-lg-3 m-xl-5">
                  <div className="image__wrapper">
                    <div className=" image__style">
                      <img className="" src={s_image} alt="s_image.PNG" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="sectionToScroll"
        className="contactUs__bottom contact_main my-5"
        ref={sectionRef}
      >
        <div className="container-fluid">
          <div className="contactUs__bottomInner ">
            <div className="text-center">
              <p className="speciality__title align-center">Let's Get</p>
              <h2 className="subtitle__font mb-lg-5">In Touch Now</h2>
            </div>
            <div className="row">
              <div className="col-lg-7 ">
                <div className="contact__form  m-sm-4 m-md-5 m-lg-0 p-lg-0 pl-lg-5">
                  <div className="contact_bg">
                    <div className="input_main">
                      <div className="container">
                        <form ref={form} onSubmit={sendEmail}>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label
                                  className="text-white"
                                  htmlFor="exampleInputEmail1"
                                >
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  className="email-bt"
                                  name="from_firstName"
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label
                                  className="text-white"
                                  htmlFor="exampleInputEmail1"
                                >
                                  Last name
                                </label>
                                <input
                                  type="text"
                                  className="email-bt"
                                  name="from_lastName"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label
                                  className="text-white"
                                  htmlFor="exampleInputEmail1"
                                >
                                  Your Email Adress
                                </label>
                                <input
                                  type="text"
                                  className="email-bt"
                                  placeholder=""
                                  name="from_email"
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label
                                  className="text-white"
                                  htmlFor="exampleInputPhone"
                                >
                                  Phone number
                                </label>
                                <input
                                  type="text"
                                  className="email-bt"
                                  name="from_phone"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label
                              className="text-white"
                              htmlFor="exampleInputEmail1"
                            >
                              Which Related Problem You Are Facing?
                            </label>
                            <select
                              className="custom__select mr-sm-2"
                              id="inlineFormCustomSelect"
                              name="from_problem"
                            >
                              <option value="0">Select One ...</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label
                              className="text-white"
                              htmlFor="exampleInputEmail1"
                            >
                              Type Your Message
                            </label>
                            <textarea
                              className="massage-bt"
                              placeholder=""
                              rows="5"
                              id="comment"
                              name="from_message"
                            ></textarea>
                          </div>
                          <div className="send_bt">
                            <input
                              type="submit"
                              value="Send"
                              className="sendbutton__style"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 mt-5 mt-md-0">
                <div className="contact__location mt-5 mt-lg-0 p-lg-0">
                  <div className="row flex-column map__responsive">
                    <div className="col p-0">
                      <iframe
                        title="google-map"
                        className="google__map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.3237079125206!2d-0.08849084413499131!3d51.52562229937303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca55fd67523%3A0x1483f30997bfd8ba!2s86-90%20Paul%20St%2C%20London%20EC2A%204NE%2C%20UK!5e0!3m2!1sen!2sbd!4v1715710591805!5m2!1sen!2sbd"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div
                      className="col text-center p-4 map__address"
                      style={{ maxHeight: "20%" }}
                    >
                      <h4 className="display-5">
                        <span className="text-white">
                          ROYAL WHARF ACCOUNTANTS LIMITED
                          <span className="d-block">3rd Floor, 86-90 Paul Street,</span>
                        </span>

                        <span className="text-yellow">London, EC2A 4NE.</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}
