import "./CompanyTax.css";
import { Link } from "react-router-dom";

export function CompanyTax() {
  return (
    <div className="companyTax">
      {/* -CompanyTax upper section start*/}

      <section id="CompanyTax_upper__section">
        <div className="companyTax__backgroundImage">
          <div className="dark-overlay">
            <div className="companyTax__inner">
              <div className="container-fluid fix__margin">
                <div className="row pt-3 pt-md-5 align-items-start">
                  <div className="col-md-12 col-lg-8 ">
                    <div className="d-flex flex-row  py-4">
                      <h1 className="display-4 title__font">
                        <strong>
                          Company Tax
                          <span className="text__color"> Return</span>
                        </strong>
                      </h1>
                    </div>
                    <div className="d-flex flex-row py-4">
                      <div className="align-self-end">
                        <p className="lead">
                          Our expert team offers Company accounts preparation
                          and Tax submission on time. Company accounts provide a
                          snapshot of a company's financial health and are used
                          for various purposes, including internal
                          decision-making, compliance, and transparency to
                          stakeholders.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row pt-0 pt-md-5">
                      <div className="align-self-end">
                        <Link to="/contactUs" className="button__style">
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -CompanyTax upper section end*/}

      {/* -CompanyTax lower section start*/}

      <section id="companyTax_lower__section" className="pt-5">
        <div className="companyTax_lower__inner">
          <div
            className="container-fluid companyTax_lower__container"
            style={{ background: "#181818" }}
          >
            <div className="row no-gutters">
              <div className="">
                <h1 className="pb-4 display-4 subtitle__font">
                  Solutions designed for
                  <span
                    className="d-block subtitle__style"
                    style={{ color: "#d3ac6a" }}
                  >
                    all your needs
                  </span>
                </h1>
                <p className="lead pb-4">
                  Our team of experienced professionals understands the
                  intricate landscape of corporate taxation. We guide you
                  through the entire process, ensuring the accurate preparation
                  and submission of your Company Tax Return, adhering to all
                  HMRC regulations.
                  <br />
                  <br />
                  Optimising your company's tax position is at the forefront of
                  our services. We go beyond compliance, identifying
                  opportunities for deductions, allowances, and incentives to
                  minimise your tax liabilities and enhance your financial
                  efficiency.
                  <br />
                  <br />
                  Timeliness is a key consideration in our approach. We ensure
                  that your Company Tax Return is prepared efficiently, meeting
                  all deadlines to avoid penalties and maintain a smooth
                  relationship with HMRC.
                  <br />
                  <br />
                  Communication is vital in the realm of corporate taxation. Our
                  team is readily available to address any queries, concerns, or
                  changes in your business that may impact your tax obligations.
                  <br />
                  <br />
                  Contact us today to discuss your corporate tax needs and
                  discover how we can contribute to the financial success of
                  your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -CompanyTax lower section end*/}
    </div>
  );
}
