import "./Payroll.css";
import { Link } from "react-router-dom";

export function Payroll() {
  return (
    <div className="payroll">
      {/* -Payroll upper section start*/}

      <section id="payroll_upper__section">
        <div className="payroll__backgroundImage">
          <div className="dark-overlay">
            <div className="payroll_inner">
              <div className="container-fluid fix__margin">
                <div className="row align-items-start">
                  <div className="col-md-12 col-lg-8 ">
                    <div className="d-flex flex-row  py-4">
                      <h1 className="display-4 title__font">
                        <strong>Payroll</strong>
                        <br />
                      </h1>
                    </div>
                    <div className="d-flex flex-row py-4">
                      <div className="align-self-end">
                        <p className="lead">
                          The Royal Wharf Accountants team will handle tasks
                          like calculating employee wages, managing tax
                          withholdings, ensuring compliance with tax
                          regulations, and maintaining accurate payroll records.
                          We also provide timely reporting to government
                          agencies, reducing compliance risks for businesses.
                          Overall, Our services streamline payroll processes,
                          enhance accuracy, and free up businesses to focus on
                          core operations while ensuring financial compliance.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row py-5">
                      <div className="align-self-end">
                        <Link to="/contactUs" className="button__style">
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -Payroll upper section end*/}

      {/* -Payroll lower section start*/}

      <section id="payroll_lower__section" className="py-5">
        <div className="payroll_lower__inner">
          <div
            className="container-fluid payroll_lower__container"
            style={{ background: "#181818" }}
          >
            <div className="row no-gutters">
              <div className="">
                <h1 className="pb-4 display-4 subtitle__font">
                  Solutions designed for
                  <span
                    className="d-block subtitle__style"
                    style={{ color: "#d3ac6a" }}
                  >
                    all your needs
                  </span>
                </h1>
                <p className="lead pb-4">
                  Our experienced team understands the significance of payroll
                  accuracy. We meticulously handle salary calculations, tax
                  withholdings, and other deductions to guarantee that your
                  employees are paid correctly and promptly.
                  <br />
                  <br />
                  Compliance with ever-evolving payroll regulations is a
                  cornerstone of our services. You can trust us to stay abreast
                  of the latest legislative changes, minimising risks and
                  ensuring your payroll processes align with legal requirements.
                  <br />
                  <br />
                  Beyond the technicalities, we provide a human touch to our
                  payroll services. Our team is readily available to address any
                  queries or concerns you or your employees may have, fostering
                  a positive and collaborative working relationship.
                  <br />
                  <br />
                  Partner with Royal Wharf Accountants for seamless payroll
                  management, allowing you to focus on your core business
                  activities. Contact us today to discuss how our payroll
                  services can contribute to the efficiency and success of your
                  organisation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -Payroll lower section end*/}
    </div>
  );
}
