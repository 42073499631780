import "./Quote.css";
import { Link } from "react-router-dom";

export function Quote() {
  return (
    <section id="quote__section" className="py-5">
      <div className="quote__inner">
        <div
          className="container-fluid quote__container"
          style={{ background: "#181818" }}
        >
          <div className="row">
            <div className="col-12 col-xl-7">
              <h1 className="display-3 header__font">
                <span className="text-white">
                  <strong>Get a</strong>{" "}
                </span>
                <span className="text__color"> quote</span>
              </h1>
              <span className="quote__subtitle">
                "Let's grow your business together"
              </span>
            </div>
            <div className="col-12 col-xl-5 pt-5 pt-xl-0 align-self-center">
              <Link to="/contactUs" className="button__style">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
