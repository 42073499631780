import "./App.css";
import "./App.scss";
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home.js";
import { OtherServices } from "./UI/OtherServices";
import { ContactUs } from "./UI/ContactUs";
import { Vat } from "./UI/Vat";
import { Quote } from "./components/otherService/Quote";
import { CompanyAccounts } from "./UI/CompanyAccounts";
import { CompanyTax } from "./UI/CompanyTax";
import Footer from "./components/Footer";
import Header from "./components/common/Header";

 function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/contactUs') {
      const section = document.getElementById('sectionToScroll');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block:"start" });
      }
    } else{
      window.scrollTo({top: 0, behavior: "smooth"});
    }
  }, [pathname]);

  return null;
}

function App() {

  return (
    <Router>
      <div className="app">
      <ScrollToTop />
      <Header/>
        <Routes>
          <Route
            path="/contactUs"
            element={[<ContactUs />]}
          ></Route>
          <Route
            path="/otherServices"
            element={[<OtherServices />]}
          ></Route>
          <Route
            path="/company&Accounts"
            element={[
              <CompanyAccounts />,
              <CompanyTax />,
              <Quote />
            ]}
          ></Route>
          <Route
            path="/vat"
            element={[<Vat />]}
          ></Route>
          <Route path="/" element={[<Home />]}></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
