import React from 'react'
import "./OtherServices.css";
import { Offers } from '../components/otherService/offers';
import { Properties } from '../components/otherService/properties';
import { NewCompany } from '../components/otherService/newCompany';
import { CashFlow } from '../components/otherService/cashFlow';
import { DebtRepo } from '../components/otherService/debtRepo';
import { Quote } from '../components/otherService/Quote';

export function OtherServices() {
  return (
    <div className='Services'>
        <Offers/>
        <Properties />
        <NewCompany />
        <CashFlow />
        <DebtRepo />
        <Quote />
    </div>
  )
}
